import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import * as utils from './../utils'

gsap.registerPlugin(ScrollTrigger)

// header
export function header() {
  const body = document.body

  ScrollTrigger.create({
    trigger: 'body',
    start: 'top top',
    onUpdate: (self) => {
      if(self.direction == 1) {
        body.classList.add('header-is-hidden')
      } else {
        body.classList.remove('header-is-hidden')
      }
    }
  });
}

// hero
export function hero() {
  const $ = gsap.utils.selector('#map-hero')
  const el = document.getElementById('map-hero')
  const mapGradient = document.querySelector('#map-bg .gradient')
  const tl = gsap.timeline({})

  tl
    .to([
      $('.hero-title'),
      $('.hero-description'),
      $('.buttons'),
    ], {
      opacity: 0,
      y: -50,
      x: () => {
        if(!utils.isMobile.any()) {
          return '-50'
        }
      },
      stagger: .1
    })
    .to($('.artboard-turbine'), {
      x: () => {
        if(!utils.isMobile.any()) {
          return '-50%'
        }
      },
      y: '10%',
      scale: 1.2
    }, 0)
    .to(mapGradient, {
      opacity: 0
    })

  if(el) {
    ScrollTrigger.create({
      id: 'hero',
      trigger: el,
      start: 'top top',
      scrub: true,
      animation: tl
    })
  }
}

// map
export function map() {
  const mapHero = document.querySelector('#map-hero')
  const el = document.getElementById('map')
  const separator = document.getElementById('map-separator')
  const $ = gsap.utils.selector('#map')
  const tl = gsap.timeline({})

  tl
    .to(mapHero, {
      opacity: 0
    })
    .to($('.map-wrapper'), {
      opacity: 1,
      pointerEvents: 'auto'
    })

  if(el) {
    ScrollTrigger.create({
      id: 'map',
      trigger: el,
      start: 'top 50%',
      end: 'bottom 50%',
      scrub: true,
      animation: tl
    })

    ScrollTrigger.create({
      id: 'map-separator',
      trigger: separator,
      start: 'top 50%',
      onEnter: () => {
        // trigger 12 states in map to change colors
        console.log('12 states should change colors here')
      },
      onLeaveBack: () => {
        // revert back
      }
    })
  }
}

// states
export function states(inputs) {
  const el = document.getElementById('map-states')
  const $ = gsap.utils.selector('#map-states')
  const tl = gsap.timeline({})

  tl
    .from($('.content-1 > *'), {
      opacity: 0,
      y: 50,
      stagger: .1
    })
    .to($('.content-1'), {
      opacity: 0
    })
    .set($('.content-1'), {
      display: 'none'
    })
    .set($('.content-2'), {
      display: 'block'
    })
    .fromTo($('.content-2 > *'), {
      opacity: 0,
      y: 50
    }, {
      opacity: 1,
      y: 0,
      stagger: .1
    })
    .to($('.content-2'), {
      opacity: 0
    })
    .set($('.content-2'), {
      display: 'none'
    })
    .set($('.content-3'), {
      display: 'block'
    })
    .fromTo($('.content-3 > *'), {
      opacity: 0,
      y: 50
    }, {
      opacity: 1,
      y: 0,
      stagger: .1
    })

  if(el) {
    ScrollTrigger.create({
      // markers: true,
      id: 'states',
      trigger: el,
      start: 'top 50%',
      end: 'bottom 100%',
      scrub: true,
      animation: tl
    })

    ScrollTrigger.create({
      // markers: true,
      id: 'morph-turbine',
      trigger: $('.trigger-second'),
      start: 'top 50%',
      onEnter: () => {
        inputs.turbineIsHidden.value = true
      },
      onLeaveBack: () => {
        inputs.turbineIsHidden.value = false
      }
    })

    ScrollTrigger.create({
      // markers: true,
      id: 'states-graph',
      trigger: $('.trigger-third'),
      start: 'top 50%',
      onEnter: () => {
        inputs.graphIsActive.value = true
      },
      onLeaveBack: () => {
        inputs.graphIsActive.value = false
      }
    })
  }
}

// filter
export function filter() {
  const el = document.getElementById('map-filter-wrapper')
  const body = document.body
  if(el) {
    ScrollTrigger.create({
      // markers: true,
      id: 'filter',
      trigger: el,
      start: 'top top',
      onToggle: (self) => {
        if(self.isActive) {
          body.classList.add('filter-is-shown')
        } else {
          body.classList.remove('filter-is-shown')
        }
      }
    })
  }
}