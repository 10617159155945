import gsap from "gsap"

export default class Card {
  constructor() {
    this.$ = gsap.utils.selector('#map-card')
    this.wrapper = document.getElementById('map-card-wrapper')
    this.card = document.getElementById('map-card')
    this.cards = [...this.wrapper.querySelectorAll('.card-item')]
    this.popupTrigger = this.wrapper.querySelector('.popup-trigger')
    this.closer = this.wrapper.querySelector('#map-closer')
    this.selects = [...document.querySelectorAll('.select-state')]
    this.navSelect = document.querySelector('#map-filter select')
    this.activeCard = ''

    this.start()
  }

  start() {
    if(this.wrapper) {
      this.close()
      this.activeCard = this.cards[0]
      this.selects.forEach((select, index) => {
        select.addEventListener('change', () => {
          const value = select.selectedOptions[0].value
          if(value !== 'reset') {
            this.morph()
            this.applySelect(select.selectedOptions[0].value, index)
            const nextCard = this.filterCard(select.selectedOptions[0].value)
            this.swapContent(this.activeCard, nextCard)
          } else {
            this.demorph()
          }
        })
      })
    }
  }

  close() {
    this.closer.onclick = () => {
      this.demorph()
    }
  }

  applySelect(value, selectedIndex) {
    this.popupTrigger.setAttribute('data-type', 'dyk-' + value)
    this.selects.forEach((select, index) => {
      if(index != selectedIndex) {
        select.value = value
      }
    })
  }

  filterCard(type) {
    let el = ''
    this.cards.forEach(card => {
      if(card.dataset.type == type) {
        el = card
        return
      }
    })
    return el
  }

  swapContent(lastCard, nextCard) {
    const tl = gsap.timeline({
      defaults: {
        ease: 'power2.out',
        duration: .3
      },
      onComplete: () => {
        tl.clear()
      }
    })
  
    tl
      .to(lastCard, {
        opacity: 0,
        y: -15
      })
      .set(nextCard, {
        opacity: 0,
        y: 15,
        onComplete: () => {
          this.card.scrollTo(0, 0)
          lastCard.classList.remove('is-active')
          nextCard.classList.add('is-active')
          this.activeCard = nextCard
        }
      })
      .to(nextCard, {
        opacity: 1,
        y: 0
      })
  }

  morph() {
    document.body.style.overflow = 'hidden'

    const tl = gsap.timeline({
      defaults: {
        ease: 'power2.out',
        duration: .15
      },
      onComplete: () => {
        tl.clear()
      }
    })
    tl
      .to(this.$('#map-legends'), {
        opacity: 0,
        pointerEvents: 'none',
        onComplete: () => {
          this.wrapper.classList.add('show-tabs')
        }
      })
      .set(this.$('#map-legends'), {
        display: 'none'
      })
      .set(this.$('#map-states'), {
        display: 'block'
      })
  }

  demorph() {
    this.navSelect.value = 'reset'
    const tl = gsap.timeline({
      defaults: {
        ease: 'power2.out',
        duration: .15
      },
      onComplete: () => {
        tl.clear()
        document.body.style.overflow = ''
      }
    })
    tl
      .to(this.$('#map-states'), {
        opacity: 0,
        pointerEvents: 'none',
        onComplete: () => {
          this.wrapper.classList.remove('show-tabs')
        }
      })
      .set(this.$('#map-states'), {
        clearProps: 'all'
      })
      .set(this.$('#map-legends'), {
        display: 'block'
      })
      .to(this.$('#map-legends'), {
        opacity: 1
      })
      .set(this.$('#map-legends'), {
        clearProps: 'all'
      })
  }

}