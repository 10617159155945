import * as scroll from './map/scroll'
import * as interactions from './map/interactions'
// import svgFilter from './map/filter'
import Tabs from './map/tabs'
import Card from './map/card'

const rive = require('@rive-app/canvas')
const riveFile = new URL('../rive/turn_forward.riv', import.meta.url)

// set scroll position to 0
window.addEventListener('unload', function (e) {
	window.scrollTo(0, 0)
})

class App {
	constructor() {
		this.numberOfLoadedArtboards = 0
		this.artboardNames = ['Turbine', 'TurbineIcon', 'Wave']
		this.artboardSelectors = ['turbine', 'turbine-icon', 'wave']
		this.userInputs = {
			Turbine: [],
			TurbineIcon: ['turbineIsHidden', 'graphIsActive'],
			Wave: [],
		}
		this.artboards = {}
		this.artboardInputs = {}
	}

	loadArtboards() {
		this.artboardNames.forEach((name, i) => {
			// insert blank object to inputs object
			this.artboardInputs[name] = {}
			this.artboards[name] = new rive.Rive({
				src: riveFile,
				artboard: name,
				stateMachines: 'SM',
				canvas: document.querySelector(
					`.artboard-${this.artboardSelectors[i]}`
				),
				onLoad: () => {
					this.artboards[name].resizeDrawingSurfaceToCanvas()
					this.artboards[name].play()
					this.artboardLoaded()
				},
			})
		})
	}

	artboardLoaded() {
		this.numberOfLoadedArtboards += 1
		if (this.numberOfLoadedArtboards == this.artboardNames.length) {
			this.assignInputs()
		}
	}

	assignInputs() {
		// repeat through user inputs and assign them to the artboard inputs object
		Object.keys(this.userInputs).forEach((objectName) => {
			this.artboardInputs[objectName] =
				this.artboards[objectName].stateMachineInputs('SM')
			this.userInputs[objectName].forEach((input) => {
				this.artboardInputs[objectName][input] = this.artboardInputs[
					objectName
				].find((i) => i.name === input)
			})
		})
		this.start()
	}

	start() {
		interactions.loaded()

		this.tabs = new Tabs()
		this.card = new Card()

		scroll.header()
		scroll.hero()
		scroll.map()
		scroll.filter()
		scroll.states(this.artboardInputs.TurbineIcon)

		interactions.popup()
	}
}

document.addEventListener('DOMContentLoaded', () => {
	const app = new App()
	app.loadArtboards()
})
