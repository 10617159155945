import gsap from 'gsap'
import * as utils from './../utils'

export function popup() {
	const popup = document.getElementById('map-popup')
	const closer = document.querySelector('.popup-closer')
	const triggers = [...document.querySelectorAll('.popup-trigger')]
	let type = ''

	if (popup) {
		triggers.forEach((trigger) => {
			trigger.addEventListener('click', function () {
				type = this.dataset.type
				popup.classList.add('is-popup-active')
				const activePopup = document.getElementById('popup-' + type)
				activePopup.classList.add('is-active')
				document.body.style.overflow = 'hidden'
			})
		})

		closer.addEventListener('click', () => {
			popup.classList.add('is-popup-inactive')
			document.body.style.overflow = 'auto'
			setTimeout(() => {
				popup.classList.remove('is-popup-active')
				popup.classList.remove('is-popup-inactive')
				const activePopup = document.querySelector('.popup-item.is-active')
				activePopup.classList.remove('is-active')
			}, 300)
		})
	}
}

export function loaded(callback) {
	const $ = gsap.utils.selector('#map-loader')
	const $hero = gsap.utils.selector('#map-hero')
	const wrapper = document.getElementById('map-loader')
	const tl = gsap.timeline({
		onComplete: () => {
			document.body.classList.remove('overflow-hidden')
			wrapper.style.display = 'none'
		},
	})

	if (wrapper) {
		tl.set($hero('#particles-wrapper'), {
			opacity: 0,
		})
			.set($hero('.content > div'), {
				delay: 0.3,
				opacity: 0,
				y: 50,
			})
			.set($hero('.artboard-turbine'), {
				x: () => {
					if (!utils.isMobile.any()) {
						return '-50%'
					}
				},
				y: '50%',
				rotate: '-50deg',
				opacity: 0,
				transformOrigin: 'bottom',
			})

			.to($('.loader-icon'), {
				scale: 0,
				ease: 'power2.out',
			})
			.to($('.loader-bg'), {
				scaleY: 0,
				duration: 0.5,
				ease: 'power2.out',
			})
			.to(
				$hero('.artboard-turbine'),
				{
					y: 0,
					rotate: '0deg',
					opacity: 1,
					duration: 2,
					ease: 'expo.out',
				},
				'-=.5'
			)
			.to(
				$hero('.artboard-turbine'),
				{
					x: 0,
					duration: 1,
					ease: 'power2.out',
				},
				'-=1'
			)
			.to(
				$hero('.content > div'),
				{
					onStart: () => {
						callback()
					},
					opacity: 1,
					y: 0,
					stagger: 0.1,
				},
				'<'
			)
			.to(
				$hero('#particles-wrapper'),
				{
					opacity: 1,
					ease: 'power2.out',
				},
				'<'
			)
	}
}
