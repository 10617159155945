import gsap from "gsap"

export default class Tabs {
  constructor() {
    this.tabs = [...document.querySelectorAll('.sumatrancat-tabs')]
    this.start()
  }

  start() {
    if(this.tabs.length > 0) {
      this.tabs.forEach(tabs => {
        const triggers = [...tabs.querySelectorAll('.tab-trigger')]
        const contents = [...tabs.querySelectorAll('.tab-content')]
        new Triggers(triggers, contents)
      })
    }
  }
}

class Triggers {
  constructor(triggers, contents) {
    this.wrapper = document.getElementById('map-card')
    this.triggers = triggers
    this.contents = contents
    this.activeTrigger = this.triggers[0]

    this.setup()
    this.click()
  }

  setup() {
    this.activeTrigger.classList.add('is-active')

    this.activeContent = this.filterContent(this.activeTrigger.dataset.type)
    this.activeContent.classList.add('is-active')
  }

  click() {
    this.triggers.forEach((trigger, i) => {
      trigger.addEventListener('click', () => {
        if(!trigger.classList.contains('is-active')) {
          this.activeTrigger.classList.remove('is-active')
          trigger.classList.add('is-active')
          const nextContent = this.filterContent(trigger.dataset.type)
          this.swapContent(this.activeContent, nextContent)
          this.activeTrigger = trigger
        }
      }) 
    })
  }

  swapContent(lastContent, nextContent) {
    const tl = gsap.timeline({
      defaults: {
        ease: 'power2.out',
        duration: .3
      },
      onComplete: () => {
        tl.clear()
      }
    })

    tl
      .to(lastContent, {
        opacity: 0,
        x: 30,
      })
      .set(nextContent, {
        opacity: 0,
        x: -30,
        onComplete: () => {
          this.wrapper.scrollTo(0, 0)
          lastContent.classList.remove('is-active')
          nextContent.classList.add('is-active')
          this.activeContent = nextContent
        }
      })
      .to(nextContent, {
        opacity: 1,
        x: 0
      })
  }

  filterContent(type) {
    let el = ''
    this.contents.forEach(content => {
      if(content.dataset.type == type) {
        el = content
        return
      }
    })
    return el
  }
}